import {
  Box,
  Container,
  Text,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  VStack,
  Icon,
  Stack,
  Image, Button
} from "@chakra-ui/react";
import {CheckIcon, CloseIcon} from "@chakra-ui/icons";
import logo from "../assets/icon.png";
const ComparisonPage = () => {
  const features = [
    {
      feature: "Codebase Understanding",
      description: "Sweep analyzes your entire codebase using a custom code graph, your current open files, and recent IDE activity to provide deeply contextual assistance.",
      sweep: true,
      copilot: false
    },
    {
      feature: "Instant Apply",
      description: "Preview AI-suggested changes instantly with inline diffs generated at 5000 tokens/second.",
      sweep: true,
      copilot: false
    },
    {
      feature: "Enterprise Security",
      description: "Deploy Sweep in air-gapped environments to keep your code secure and on-premises.",
      sweep: true,
      copilot: false
    },
    {
      feature: "Quickly add code to context",
      description: "Sweep allows you to \"@ mention\" or select code to add to context.",
      sweep: true,
      copilot: false
    },
    {
      feature: "Version Control with Checkpoints",
      description: "Review and rollback AI changes with built-in checkpoints.",
      sweep: true,
      copilot: false
    },
    {
      feature: "Performance",
      description: "Sweep uses <1 GB of IDE memory while Copilot can take over 5 GBs of memory",
      sweep: true,
      copilot: false
    },
    {
      feature: "Custom Rules",
      description: "Sweep allows you to add custom rules for the AI per project.",
      sweep: true,
      copilot: false
    },
    {
      feature: "Dedicated Support",
      description: "Sweep provides slack + email support, monthly training, and personalized developer feedback sessions.",
      sweep: true,
      copilot: false
    }
  ];

  return (
      <Container maxW="1400px" px={{base: 2, md: 8}} overflow="hidden">
      <Stack
            textAlign={"center"}
            align={"center"}
            spacing={{base: 4, md: 10}}
            py={{base: 4, md: 15}}
            pb={{base: 8, md: 20}}
            width="100%"
      >
        <VStack spacing={6} align="stretch" width="100%">
        <Text fontSize={{base: "3xl", sm: "4xl", md: "6xl"}} fontWeight="bold" textAlign="center" px={2}>
          Sweep vs GitHub Copilot
        </Text>
          <Text fontSize={{base: "md", sm: "lg", md: "xl"}} textAlign="center" pb={6} px={2}>
          See why enterprises choose Sweep over GitHub Copilot
          </Text>
          <Box overflowX="auto" width="100%" mx={0}>
            <Table variant="simple" size={{base: "sm", md: "md"}} width="100%">
            <Thead>
                <Tr>
                  <Th fontSize={{base: "md", md: "xl"}} maxW="2xl" color="white" px={{base: 2, md: 6}}
                      width="50%">Features</Th>
                  <Th fontSize={{base: "md", md: "lg"}} textAlign="center" px={{base: 1, md: 6}} width="25%"
                      bg="whiteAlpha.100" borderRadius="md">
                  <Button
                      variant="ghost"
                      py={{base: 2, md: 6}}
                      fontSize={{base: "md", md: "xl"}}
                      textTransform="none"
                      color="white"
                      _hover={{ bg: "transparent" }}
                      cursor="default"
                  >
                      <Image src={logo} alt="logo" width={{base: 6, md: 10}} borderRadius={12}/>
                    Sweep AI
                    </Button>
                  </Th>
                  <Th fontSize={{base: "md", md: "lg"}} textAlign="center" textTransform="none" color="white"
                      px={{base: 1, md: 6}} width="25%">GitHub Copilot</Th>
                </Tr>
            </Thead>
              <Tbody>
                {features.map((item, index) => (
                  <Tr key={index}>
                    <Td maxW="xl" py={{base: 4, md: 12}} px={{base: 2, md: 6}}>
                      <Text as="h2" fontSize={{base: "lg", md: "xl"}} fontWeight="bold" mb={2} color="white">
                      {item.feature}
                      </Text>
                      <Text color="gray.300" fontSize={{base: "sm", md: "md"}}>
                      {item.description}
                      </Text>
                    </Td>
                    <Td textAlign="center" py={{base: 4, md: 6}} px={{base: 1, md: 6}}
                        bg="whiteAlpha.100">
                      <Icon
                        as={CheckIcon}
                        color="white"
                        boxSize={{base: 6, md: 8}}
                        p={1.5}
                        bg="green.500"
                        borderRadius="full"
                      />
                    </Td>
                    <Td textAlign="center" py={{ base: 4, md: 6 }} px={{ base: 1, md: 6 }}>
                      <Icon as={CloseIcon} color="gray.200" boxSize={{ base: 4, md: 5 }} />
                    </Td>
                  </Tr>
                ))}
              </Tbody>
            </Table>
          </Box>
        </VStack>
      </Stack>
    </Container>
  );
};

export default ComparisonPage;