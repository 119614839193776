import React from 'react';
import { Box, Text, Stack, Flex, Button } from '@chakra-ui/react';

const SecurityPage: React.FC = () => (
    <Box
        p={{ base: 4, md: 8 }}
        backgroundColor="transparent"
        py={{ base: 8, md: 12 }}
        display="flex"
        flexDirection="column"
        alignItems="center"
    >
      <Stack spacing={6} maxW="4xl" textAlign="center" mb={16} px={{ base: 4, md: 0 }}>
        <Text
            fontSize={{ base: "4xl", md: "6xl" }}
            fontWeight="bold"
        >
          Enterprise
        </Text>
        <Flex
          direction="column"
          borderRadius={10}
          background="rgba(51, 40, 91, 0.5)"
          p={{ base: 6, md: 8, lg: 16 }}
          width="100%"
          maxWidth={{ base: "100%", md: "50vw" }}  // Added to match AboutUs.tsx width
          mx="auto"  // Added to center the container
        >
          <Text fontSize={{ base: "xl", md: "2xl" }} color="gray.300" textAlign="left" mb={8}>
            Sweep can run entirely on-prem (air-gapped deployments).
            <br/><br/>
            We support OpenAI, OpenAI Enterprise, Azure Openai, Anthropic, and AWS Bedrock.
            <br/><br/>
            No code ever leaves your VPC.
          </Text>
          <Button
            colorScheme="purple"
            bgColor="gray.100"
            onClick={() => window.open('https://calendly.com/william-sweep/intro-call', '_blank')}
            size="lg"
            width={{ base: "100%", md: "auto" }}
            px={8}
            alignSelf="center"
          >
            Book a demo
          </Button>
        </Flex>
      </Stack>
    </Box>
);

export default SecurityPage;