import { Box, Button, Container, Text, VStack } from "@chakra-ui/react";

export default function Conclusion() {
    return (
        <Box
            bgPos="center"
            bgSize="cover"
            py={32}
            px={8}
            mt={"3rem !important"}
        >
            <Container
                maxW="5xl"
                bgPos="-100px 0"
                bgSize="cover"
                px={8}
            >
                <VStack spacing={"2rem"} alignItems="center">
                    <Text
                        mt="8rem !important"
                        mb="2rem !important"
                        fontSize={{ base: "xl", sm: "2xl", md: "3xl" }}
                        textAlign="center"
                    >
                        Try out Sweep
                    </Text>
                    <Button
                        color="white"
                        p={8}
                        colorScheme={"purple"}
                        bg={"purple.700"}
                        _hover={{ bg: "purple.600" }}
                        onClick={() => window.open("https://docs.sweep.dev/#install-sweep")}
                        fontSize={"xl"}
                        maxWidth={"300px"}
                        mt="3rem !important"
                        mb="8rem !important"
                    >
                        Install Sweep
                    </Button>
                </VStack>
            </Container>
        </Box>
    )
}