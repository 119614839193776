import React from 'react';
import { Box, Image, Text, SimpleGrid, VStack, Button, Stack } from '@chakra-ui/react';

interface TeamMemberProps {
  name: string;
  description: string;
  imageSrc: string;
}

const TeamMember: React.FC<TeamMemberProps> = ({
  name,
  description,
  imageSrc
}) => (
  <Stack
    direction={{ base: 'column', md: 'row' }}
    spacing={8}
    alignItems="center"
    p={{ base: 4, md: 8 }}
    borderRadius={10}
    background="rgba(51, 40, 91, 0.8)"
    width="100%"
    maxWidth={{ base: "100%", md: "50vw" }}
    mx="auto"
  >
    <Box
      width={{ base: "200px", md: "250px" }}
      height={{ base: "200px", md: "250px" }}
      borderRadius="full"
      overflow="hidden"
      flexShrink={0}
    >
      <Image
        src={imageSrc}
        alt={name}
        width="100%"
        height="100%"
        objectFit="cover"
      />
    </Box>
    <VStack align={{ base: "center", md: "start" }} spacing={4} width="100%">
      <Text
        fontSize={{ base: "2xl", md: "3xl" }}
        fontWeight="semibold"
        color="white"
        textAlign={{ base: "center", md: "left" }}
      >
        {name}
      </Text>
      <Text
        color="gray.300"
        fontSize={{ base: "lg", md: "xl" }}
        lineHeight="1.8"
        textAlign={{ base: "center", md: "left" }}
      >
        {description}
      </Text>
    </VStack>
  </Stack>
);

const AboutUs: React.FC = () => (
  <Box
    p={{ base: 4, md: 8 }}
    backgroundColor="transparent"
    py={{ base: 8, md: 12 }}
    display="flex"
    flexDirection="column"
    alignItems="center"
  >
    <Stack spacing={6} maxW="4xl" textAlign="center" mb={16} px={{ base: 4, md: 0 }}>
      <Text
        fontSize={{ base: "4xl", md: "6xl" }}
        fontWeight="bold"
      >
        Join the Team
      </Text>
      <Text fontSize={{ base: "xl", md: "2xl" }} color="gray.300" px={4}>
        Our team brings experience from Roblox, CMU,<br/>and cutting-edge ML research.
      </Text>
      <Button
        color="white"
        colorScheme="purple"
        size="lg"
        bg={"purple.700"}
        _hover={{ bg: "purple.500" }}
        px={8}
        width="fit-content"
        alignSelf="center"
        onClick={() => window.open("https://www.ycombinator.com/companies/sweep/jobs/8dUn406-founding-engineer", "_blank")}
      >
        Open Positions
      </Button>
    </Stack>

    <Text
      fontSize={{ base: "4xl", md: "6xl" }}
      fontWeight="bold"
      mb={12}
    >
      Founders
    </Text>

    <SimpleGrid
      columns={1}
      maxWidth={{ base: "100%", md: "50vw" }}
      spacing={8}
      width="100%"
      px={{ base: 4, md: 0 }}
    >
      <TeamMember
        name="William Zeng"
        description="Previously led Search ML at Roblox, where he built vector search and ranking models serving 70M+ DAU."
        imageSrc={process.env.PUBLIC_URL + '/wz_pfp.png'}
      />
      <TeamMember
        name="Kevin Lu"
        description="Previously worked on Avatar ML at Roblox, including the first ML-based skin weight generation model for UGC avatars."
        imageSrc={process.env.PUBLIC_URL + '/KEVIN.jpeg'}
      />
    </SimpleGrid>
  </Box>
);

export default AboutUs;